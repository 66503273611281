import React, { Component } from 'react';
import award_ca from './assets/award_ca@2x.png';
import award_telly from './assets/award_telly@2x.png';
import award_how from './assets/award_how@2x.png';
import award_logo_lounge from './assets/award_logolounge@2x.png';

import me_mobile from './assets/me_mobile.jpg';



class Footer extends Component {

  render() {
    //can execute functions etc here
    return ( // this is the "view"
      <footer className="Footer">
        <div className="wrapper">
          <div className="info">
            <p>Tip Top Studio is me, Ben Harris. While serving as a creative leader at branding agencies, and technology companies for over 20 years, I gained a unique understanding of the challenges brands often face when telling their story.</p>
            <p>That's why I founded Tip Top Studio. I saw a way to build a closer relationship between the client and the creative team&mdash;resulting in more strategic design, a higher standard of quality, and a more efficent process. </p>
            <p>Let&#x27;s work together to make big things happen with great design.</p>
            <img className="me-mobile" src={me_mobile} />

            <h3 className="servicesHeader">Services</h3>
            <div className="services">
              <ul>
                <li>Brand Design</li>
                <li>Graphic Design</li>
                <li>Web/UI Design</li>
                <li>Web Development</li>
              </ul>
              <ul>
                <li>Illustration</li>
                <li>Photography</li>
                <li>Animation</li>
                <li>3D Modeling</li>
              </ul>
            </div>
            <div className="contact">
            <a href="mailto:hello@tiptopstudio.com" className="button">Say Hello</a>
            </div>
            <div className="awards">
              <img className="award" src={award_ca} alt="Communication Arts Award Winner"/>
              <img className="award" src={award_telly} alt="Telly Award Winner"/>
              <img className="award" src={award_how} alt="HOW Design Award Winner"/>
              <img className="award" src={award_logo_lounge} alt="Logo Lounge 14"/>
            </div>
          </div>
          <div></div>
        </div>
      </footer>
    );
  }
}

export default Footer;
