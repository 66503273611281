import React, { Component } from 'react';
import ReactGA from 'react-ga';
import World from './World';
import LineUtility from './LineUtility';
import Clock from './Clock';
import Utilities from './Utilities';
import hand from '../assets/hand.svg';


class TopGame extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gameInitiated: false,
      clockStyle: "hidden", // hidden, running, stopped
      seconds: "0",
      milliseconds: ":00"
    };
    // bind scope of callbacks
    this.onResize = this.onResize.bind(this);
    this.onRenderClock = this.onRenderClock.bind(this);
    this.onInitiateGame = this.onInitiateGame.bind(this);
    this.onSpin = this.onSpin.bind(this);
    this.onStopSpin = this.onStopSpin.bind(this);
    this.clock = new Clock({ render:this.onRenderClock });
  }

  componentDidMount() {
    let _this = this;
    this.worldLayer = new World({ canvas: document.querySelector('#c3d'), onStopSpin: this.onStopSpin, ...this.props });
    this.lineLayer = new LineUtility({ canvas: document.querySelector('#c2d'), onStopSpin: this.onSpin });

    window.addEventListener("resize", _this.onResize);
    this.onResize();
  }

  componentWillUnmount() {
    let _this = this;

    this.clock.stopTimer();
    this.worldLayer.unmounted();
    this.lineLayer.unmounted();
    window.removeEventListener("resize", _this.onResize);
  }

  onResize() {
    let w = window.innerWidth;
    let h = window.innerHeight - 150;
    this.worldLayer.onResize(w, h);
    this.lineLayer.onResize(w, h);

    // let vh = window.outerHeight * 0.01;
    // document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  onInitiateGame(event) {
    this.setState({ gameInitiated: true });
    this.lineLayer.onFirstMouseDown(event);
    ReactGA.event({
      category: 'Gameplay',
      action: 'Initiated'
    });
  }

  onSpin(a, s) {
    let angle = Utilities.limit(a, .2);
    let speed = s / 3;
    speed = Utilities.limit(speed / 2, 70 + (Math.random() * 20));
    speed -= Math.abs(angle) * 80; // angle penalty
    this.worldLayer.spin(angle, speed);
    this.clock.startTimer();

    ReactGA.event({
      category: 'Gameplay',
      action: 'Spin',
      value: speed
    });

    //this.setState({ clockStyle: "running" });
    this.setState({ clockStyle: "hidden" });

  }

  onStopSpin() {
    this.clock.stopTimer();
    //this.setState({ clockStyle: "stopped" });
    this.setState({ clockStyle: "hidden" });
  }

  onRenderClock(s, ms) {
    this.setState({
      seconds: s,
      milliseconds: ms
    });
  }

  renderHand() {
    if(this.state.gameInitiated === true) return;
    else return <img src={hand} alt="swipe to spin the top" className="hand" onPointerDown={ (e) => this.onInitiateGame(e) } />;
  }

  render() {
    return (
      <section className="TopGame fullHeight">
        <canvas id="c3d"></canvas>
        <div className={ `clock ${this.state.clockStyle}` }>
          <div className="seconds">{ this.state.seconds }</div>
          <div className="milliseconds">{ this.state.milliseconds }</div>
        </div>
        <canvas id="c2d"></canvas>
        { this.renderHand() }
      </section>
    );
  }
}

export default TopGame;
