import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import Modal from './Modal';
import eyes from './assets/eyes.svg';


class Tile extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true
    };

    // id:
    // size:
    // action:
    // thumbmedia:
    // thumburl:
    // thumbcaption:
    // detailmedia:
    // detailurl:
    // detailcaption:
  }

  handleClick() {
    ReactGA.event({
      category: 'User',
      action: 'Create an Account'
    });
  }

  componentDidMount() {
    //this.state.isLoading = false;
  }
  // Decide whether to add a video or image thumbnail
  renderMedia() {
    switch(this.props.thumbmedia) {
      case "video":
        return(
          <video
          autoPlay={true}
          loop={true}
          muted={true}>
            <source src={this.props.thumburl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        );

      case "image":
        return(
          //<div class="thumbImg" styl{e={{backgroundImage:"url("+media+")"}}></div>
          <img
            style={{ objectFit: this.props.fit || "cover" }}
            src={this.props.thumburl}
            alt={this.props.thumbcaption!=="eyes"? this.props.thumbcaption: "image"}
          />
        );

      case "iframe":
        return(
          //<div class="thumbImg" styl{e={{backgroundImage:"url("+media+")"}}></div>
          <div className="iframeContainer">
            <iframe title={ this.props.thumburl } src={this.props.thumburl} frameBorder="0"></iframe>
          </div>
        );

      default:
        return;
    }
  }

  // If thumb has a caption, add hover and click events
  renderCaption() {
    if(this.props.thumbcaption === "eyes") {
      return(
        <div className="captionContainer">
          <div className="screener"></div>
          <div className="caption"><img src={eyes} className="eyes" alt="See details" /></div>
        </div>
      );
    } else if (this.props.thumbcaption) {
      return(
        <div className="captionContainer">
          <div className="screener"></div>
          <div className="caption">{this.props.thumbcaption}</div>
        </div>
      );
    } else {
      return;
    }
  }

  openModal(e) {
    // this function is a bandaid. Refactor later
    e.preventDefault();
    Modal.show(this.props.detailmedia, this.props.detailurl, this.props.detailcaption);
  }

  render() {
    switch(this.props.action) {

      case "link":
        return(
          <Link className={"Tile "+this.props.size} id={this.props.id} to={this.props.detailurl}>
            {this.renderMedia()}
            {this.renderCaption()}
          </Link>
        );

      case "externalLink":
        return(
          <a className={"Tile "+this.props.size} id={this.props.id} href={this.props.detailurl} target="_blank" rel="noopener noreferrer">
            {this.renderMedia()}
            {this.renderCaption()}
          </a>
        );

      case "modal":
        return(
          <a className={"Tile "+this.props.size} id={this.props.id} href="/" onClick={(e) => this.openModal(e)}>
            {this.renderMedia()}
            {this.renderCaption()}
          </a>
        );

      default:
        return(
          <div className={"Tile "+this.props.size} id={this.props.id}>
            {this.renderMedia()}
            {this.renderCaption()}
          </div>
        );
    }
  }
}


export default Tile;
