import React, { Component } from 'react';
import ReactGA from 'react-ga';
import close_x from './assets/close_x.svg';
import './App.css';

// Singleton Modal class
class Modal extends Component {

  static instance;
  static show(detailurl, detailmedia) { Modal.instance._show(detailurl, detailmedia) }
  static hide() { Modal.instance._hide() }



  constructor(props) {
    super(props);

    this.state = {
        show: false,
        detailurl: "",
        detailmedia: ""
    };

    this.initializeReactGA();

    if(Modal.instance) {
      return Modal.instance;
    } else {
      Modal.instance = this;
    }
  }

  initializeReactGA() {
    ReactGA.initialize('UA-151397583-1');
  }

  _show(detailmedia, detailurl, detailcaption) {
    ReactGA.modalview(detailurl);

    this.setState({
      show: true,
      detailurl: detailurl,
      detailmedia: detailmedia
    });
  }

  _hide() {
    this.setState({
      show: false
    });
  }


  render() {

    if(!this.state.show) {
      return null;
    }

    switch( this.state.detailmedia ) {

      case "video": // Accepts a VIMEO Id as detailurl
        return (
          <div className="Modal">
            <div className="detail">
              <div>
                <iframe
                title = "iframe"
                src = {"https://player.vimeo.com/video/"+this.state.detailurl+"?autoplay=1&color=ffffff&byline=0&portrait=0"}
                frameborder="0"
                allow="autoplay; fullscreen"
                allowfullscreen>
                </iframe>
              </div>
              <script src="https://player.vimeo.com/api/player.js"></script>
            </div>
            <img onClick={() => this._hide()} src={close_x} alt="close" className="close_x"/>
          </div>
        );

      case "iframe":
        return (
          <div className="Modal">
            <div className="detail">
              <iframe
              title = "iframe"
              src = {this.state.detailurl} frameborder="0">
              </iframe>
            </div>
            <img onClick={() => this._hide()} src={close_x} alt="close" className="close_x"/>
          </div>
        );

      case "image":
        return (
          <div className="Modal">
            <div className="detail">
              <img src={this.state.detailurl} className="detail" alt="detail" />
            </div>
            <img onClick={() => this._hide()} src={close_x} alt="close" className="close_x"/>
          </div>
        );

      default:
      return null;
    }
  }
}

export default Modal;
