import React, { Component, Suspense, lazy } from 'react';
import './App.css';
const PageHome = lazy(() => import( './PageHome' ));


class Page_home_loader extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loaderClass: "",
      introClass: "loading",
      containerClass: "loading",
    }

    this.onAssetsLoaded = this.onAssetsLoaded.bind(this); // bind callbacks
    this.loadTimer = setInterval( ()=> this.updatePercent(), 4000 );
    this.percentLoaded = 20;
  }

  componentWillUnmount() {
    clearInterval(this.loadTimer);
    clearTimeout(this.loadTimer);
  }

  updatePercent() {
    if(this.percentLoaded < 80) {
      this.percentLoaded += 20;
      this.setState({loaderClass: "p" + this.percentLoaded});
    } else {
      clearInterval(this.loadTimer);
    }
  }

  onAssetsLoaded() {
    this.setState({
      loaderClass: "p100",
      introClass: "",
    });
    clearInterval(this.loadTimer);
    this.loadTimer = setTimeout( ()=> this.setState({ containerClass: "" }), 1000);
  }

  renderPreloader() {
    if(this.state.containerClass === "loading") {
      return (
        <div className="preloader fullHeight">
          <img src="assets/loader_logo.gif" alt="Loading" className="loaderLogo" />
          <div className="loaderBarContainer">
            <div className={ `loaderBar ${this.state.loaderClass}` }></div>
          </div>
        </div>
      )
    }
    else {
      return;
    }
  }

  render() {
    return (
      <>
        { this.renderPreloader() }
        <div className={ `container ${this.state.containerClass}` }>
          <Suspense fallback={""}>
            <PageHome onAssetsLoaded={ this.onAssetsLoaded } />
          </Suspense>
        </div>
        {/*
        <div className={ `intro ${this.state.introClass}` }>
          <h1>We design memorable brand experiences</h1>
          <h1>Tip Top is a design studio with a focus on high-quality branded creative.</h1>
        </div>
        */}
      </>
    );
  }
}

export default Page_home_loader;
