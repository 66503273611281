import React, { Component } from 'react';
import ReactGA from 'react-ga';
import TopGame from './topgame/TopGame';
import Nav from './Nav';
import Work from './Work';
import Footer from './Footer';

class Page_home extends Component {

  constructor(props) {
    super(props);
    // Google Analytics
    ReactGA.initialize('UA-151397583-1');
    this.scrollPosition = this.scrollPosition || 0;
  }

  componentWillUnmount() {
    //this.scrollPosition = window.scrollY;
    //alert(this.scrollPosition);
  }

  componentDidMount() {
    ReactGA.pageview('/');
    window.scrollTo(0, this.scrollPosition);
    document.title = "Tip Top Studio | Smart Design for Bold Brands";
  }

  render() {
    return (
      <div className="Home">
        {/* <TopGame onAssetsLoaded={ this.props.onAssetsLoaded } />*/}
        <Nav />
        <div className="wrapper">
          <Work />
        </div>
        <Footer />
      </div>
    );
  }
}

export default Page_home;
