import Point from './Point.js';
import { easeInQuad } from 'easing-utils'

class LineUtility {

  constructor(props) {
    this.onFirstMouseDown = this.onFirstMouseDown.bind(this);
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseDrag = this.onMouseDrag.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onAnimationFrame = this.onAnimationFrame.bind(this);

    this.canvas = props.canvas;
    this.ctx = this.canvas.getContext("2d");
    this.spinCallback = props.onStopSpin;

    this.warm = window.getComputedStyle(document.querySelector("html")).getPropertyValue('--warm');
    this.cool = window.getComputedStyle(document.querySelector("html")).getPropertyValue('--cool');
    this.lightCool = window.getComputedStyle(document.querySelector("html")).getPropertyValue('--lightCool');
  }

  unmounted() {
    //this.spinCallback = null;
    document.removeEventListener('pointerdown', this.onMouseDown);
    document.removeEventListener('pointermove', this.onMouseDrag);
    document.removeEventListener('pointerup', this.onMouseUp);
    window.cancelAnimationFrame(this.animationRequest);
  }

  onFirstMouseDown(event) {
    this.canvas.style.touchAction = "none";
    this.mouseIsDown = true;
    this.startPoint = new Point();// used to tween line
    this.currPoint = new Point(); // used to tween line
    this.endPoint = new Point();  // used to tween line
    this.isDrawing = false;
    this.easeFactor = null;
    this.playHead = 0;
    this.line = null;
    this.startCircle = null;
    this.endCircle = null;
    this.speed = 0;

    this.animationRequest = window.requestAnimationFrame(this.onAnimationFrame);
    document.addEventListener('pointerdown', this.onMouseDown);
    this.onMouseDown(event); // carry the initial mouseDown to completion
  }

  onMouseDown(event) {
    this.mouseIsDown = true;
    this.isDrawing = true;
    this.playHead = 0;
    this.startPoint = new Point(event.pageX, event.pageY);
    this.currPoint = new Point(event.pageX, event.pageY);
    this.endPoint = new Point(event.pageX, event.pageY);
    this.easeFactor = new Point();
    document.addEventListener('pointermove', this.onMouseDrag);
    document.addEventListener('pointerup', this.onMouseUp);
  }

  onMouseDrag(event) {
    //console.log(event)
    this.speed = this.endPoint.x - event.pageX; //event.movementX doesn't work in Safari;
    this.endPoint = new Point(event.pageX, event.pageY);
  }

  onMouseUp(event) {
    this.mouseIsDown = false;
    this.endPoint = new Point(event.pageX, event.pageY);
    let angle = this.endPoint.radiansTo(this.startPoint);
    if(angle > Math.PI / 2) angle = angle - Math.PI;  // normalize angle
    if(angle < Math.PI / -2) angle = angle + Math.PI; // normalize angle
    angle *= -1

    this.spinCallback(angle, this.speed);

    document.removeEventListener('pointermove', this.onMouseDrag);
    document.removeEventListener('pointerup', this.onMouseUp);
  }

  onAnimationFrame() {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    var dist;
    if( this.isDrawing === true ) {
      dist = this.endPoint.subtract(this.startPoint);
      if( this.easeFactor.x < 1) {
        if( this.mouseIsDown === false) {
          // snap string
          this.playHead += .06;
          this.easeFactor = new Point(easeInQuad( this.playHead ), easeInQuad( this.playHead ));
          this.currPoint = this.startPoint.add(( dist.multiply( this.easeFactor ) ));
        }
        this.drawStartCircle(this.currPoint);
        this.drawLine(this.currPoint, this.endPoint);
        this.drawEndCircle(this.endPoint);
      }
      else {
        this.reset();
      }
    }
    this.animationRequest = window.requestAnimationFrame(this.onAnimationFrame);
  }

  onResize(w, h) {
    this.canvas.width = w * 2; // multiply by 2 for retina display
    this.canvas.height = h * 2;
    this.canvas.style.width = w + "px";
    this.canvas.style.height = h + "px";
    //this.ctx.setTransform(1, 0, 0, 1, 0, 0);
    this.ctx.scale(2,2)
  }

  reset() {
    if(this.startCircle) this.startCircle.remove();
    if(this.endCircle) this.endCircle.remove();
    if(this.line) this.line.remove();
  }

  drawLine(start, end) {
    this.ctx.strokeStyle = this.warm;
    this.ctx.lineWidth = 25;
    this.ctx.lineCap = 'round';
    this.ctx.beginPath();
    this.ctx.moveTo(start.x, start.y);
    this.ctx.lineTo(end.x, end.y);
    this.ctx.stroke();
  }
  drawStartCircle(c) {
    this.ctx.strokeStyle = this.cool;
    this.ctx.lineWidth = 2;
    this.ctx.beginPath();
    this.ctx.arc(c.x, c.y, 25, 0, 2 * Math.PI);// x, y, radius, start, end
    this.ctx.stroke();
  }
  drawEndCircle(c) {
    this.ctx.strokeStyle = this.lightCool;
    this.ctx.lineWidth = 2;
    this.ctx.beginPath();
    this.ctx.arc(c.x, c.y, 40, 0, 2 * Math.PI);// x, y, radius, start, end
    this.ctx.stroke();
  }


}

export default LineUtility;
