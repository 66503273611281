import React, { Component, Suspense, lazy } from 'react';
import { Route, HashRouter } from 'react-router-dom';

import PageHomeLoader from './PageHomeLoader';
import PageHome from './PageHome';

const PageTtgr              = lazy(() => import( './PageTtgr' ));
const PageBandwidth         = lazy(() => import( './PageBandwidth' ));
const PageRelayBrand        = lazy(() => import( './PageRelayBrand' ));
const PageBuffaloMap        = lazy(() => import( './PageBuffaloMap' ));
const PageFoodMap           = lazy(() => import( './PageFoodMap' ));
const PageSensualSax        = lazy(() => import( './PageSensualSax' ));
const PageRamones           = lazy(() => import( './PageRamones' ));
const PageSim               = lazy(() => import( './PageSim' ));
const PageDatagrams         = lazy(() => import( './PageDatagrams' ));
const PageBark              = lazy(() => import( './PageBark' ));
const PageBarrierBreaker    = lazy(() => import( './PageBarrierBreaker' ));
const PageLevitate           = lazy(() => import( './PageLevitate' ));



class App extends Component {

  render() {
    return (
      <HashRouter>
        {/*<Route exact path='/' component={PageHome} />*/}

        <Route exact path='/' component={PageHome} />
        <Suspense fallback={ <div>Loading...</div> }>
          <Route path="/time_to_get_a_relay" component={PageTtgr} />
          <Route path="/bandwidth" component={PageBandwidth} />
          <Route path="/relay_brand" component={PageRelayBrand} />
          <Route path="/buffalo_map" component={PageBuffaloMap} />
          <Route path="/food_map" component={PageFoodMap} />
          <Route path="/sensual_sax" component={PageSensualSax} />
          <Route path="/ramones" component={PageRamones} />
          <Route path="/sim" component={PageSim} />
          <Route path="/datagrams" component={PageDatagrams} />
          <Route path="/bark" component={PageBark} />
          <Route path="/barrier_breaker" component={PageBarrierBreaker} />
          <Route path="/levitate" component={PageLevitate} />
        </Suspense>
      </HashRouter>
    );
  }
}

export default App;
