import * as BABYLON from 'babylonjs';
import 'babylonjs-loaders';
import { ShadowOnlyMaterial } from 'babylonjs-materials';
import { GridMaterial } from 'babylonjs-materials';

import Utilities from './Utilities';

class World {

  constructor(props) {
    this.props = props;

    //this.cool = window.getComputedStyle(document.querySelector("html")).getPropertyValue('--cool');
    //this.lightCool = window.getComputedStyle(document.querySelector("html")).getPropertyValue('--lightCool');

    this.renderFrame = this.renderFrame.bind(this)

    this.spinning = false;

    this.canvas = props.canvas;
    this.engine = new BABYLON.Engine(this.canvas, true)//, {preserveDrawingBuffer: true, stencil: true});
    //this.engine.setHardwareScalingLevel(0.5)

    this.scene = new BABYLON.Scene( this.engine );
    this.scene.clearColor = new BABYLON.Color3(1, 1, 1);
    this.scene.preventDefaultOnPointerDown = false;

    // Camera, lights, and shadows

    this.camera = new BABYLON.FollowCamera("FollowCam", new BABYLON.Vector3(0, -.4, -15), this.scene);
    this.camera.fov = .4 //.7 is 50mm equivalent;
    this.camera.rotationOffset = 0;
    this.camera.heightOffset = -.1;
    this.camera.radius = -15;
    this.camera.cameraAcceleration = 0.006;
    this.camera.maxCameraSpeed = 20;

    this.camTarget = new BABYLON.Mesh("camTarget_1", this.scene);﻿﻿
    this.camTarget.position = new BABYLON.Vector3(-.06, 1.2, 0);
    this.camera.lockedTarget = this.camTarget;
    this.camTargetAnimation = null;

    const ambientLight = new BABYLON.HemisphericLight("ambientLight", new BABYLON.Vector3(0, 1, 0), this.scene);
    ambientLight.intensity = 0.2;

    const light = new BABYLON.DirectionalLight("DirectionalLight", new BABYLON.Vector3(.3, -1, -.7), this.scene);
    light.position = new BABYLON.Vector3(-.6, 5, 3);
    light.intensity = 1;

    var shadowGenerator = new BABYLON.ShadowGenerator(512, light);
    shadowGenerator.usePoissonSampling = true;
    shadowGenerator.bias = 0.00001;

    // Physics

    const gravityVector = new BABYLON.Vector3(0,-9.81, 0);
    const physicsPlugin = new BABYLON.AmmoJSPlugin();
    this.scene.enablePhysics(gravityVector, physicsPlugin);

    // Actors

    const gridMaterial = new GridMaterial("gridMaterial", this.scene);
    gridMaterial.gridRatio = 0.5;
    gridMaterial.majorUnitFrequency = 1;
    gridMaterial.mainColor = new BABYLON.Color3(1, 1, 1);
    gridMaterial.lineColor = new BABYLON.Color3(0, 0, 0);
    gridMaterial.opacity = .1;

    // Ideally we'd be able to combine the grid and ground into one material, but grid material doesn't support shadows yet.
    this.grid = BABYLON.MeshBuilder.CreatePlane('grid', { width:50, height:10 }, this.scene, false);
    this.grid.position = new BABYLON.Vector3( 0, 0.001, 0 );
    this.grid.rotation.x = Math.PI/2;
    this.grid.material = gridMaterial;

    this.ground = BABYLON.MeshBuilder.CreatePlane('ground', { width:50, height:10 }, this.scene, false);
    this.ground.rotation.x = Math.PI/2;
    this.ground.physicsImpostor = new BABYLON.PhysicsImpostor(this.ground, BABYLON.PhysicsImpostor.BoxImpostor, { mass: 0, friction: 1, restitution: 0.5 }, this.scene);
    this.ground.material = new BABYLON.StandardMaterial("shadow", this.scene);
    this.ground.receiveShadows = true;

    const edge = BABYLON.MeshBuilder.CreatePlane("edge", {width: 10, size:.006, tileSize:1}, this.scene);
    const edgeMaterial = new BABYLON.StandardMaterial("edgeMaterial_1", this.scene);
    edgeMaterial.emissiveColor = new BABYLON.Color3.FromHexString("#EF5036");
    edge.position = new BABYLON.Vector3( 0, 0, -5 );
    edge.material = edgeMaterial;

    const _this = this;

    BABYLON.SceneLoader.ImportMesh("", "/assets/", "top.glb", this.scene,
    function(newMeshes) {
      _this.top = new BABYLON.Mesh("", _this.scene);
      _this.top.position = new BABYLON.Vector3( 0, 0, 0 );// can be used to move the center of gravity slightly offset;

      var alphamat = new BABYLON.StandardMaterial('alphamat', _this.scene);
      alphamat.diffuseColor = BABYLON.Color3.Black();
      alphamat.alpha = 0;

      var graymat = new BABYLON.StandardMaterial('graymat', _this.scene);
      graymat.specularColor = new BABYLON.Color3(.5, .5, .5);
      graymat.diffuseColor = new BABYLON.Color3(.5, .5, .5);


      var pbr = new BABYLON.PBRSpecularGlossinessMaterial("pbr", _this.scene);
      pbr.diffuseColor = new BABYLON.Color3(0, 0, 0);
      pbr.specularColor = new BABYLON.Color3(0.2, 0.2, 0.2);
      pbr.glossiness = .1;

      const topMesh = newMeshes[1];
      topMesh.position = new BABYLON.Vector3( 0, .08, 0 );
      topMesh.material = pbr;

      shadowGenerator.addShadowCaster(topMesh, true); // second arg is whether to include decendants

      _this.tip = BABYLON.MeshBuilder.CreateSphere("tip1", {diameter: 0.09}, _this.scene);
      _this.tip.position = new BABYLON.Vector3( .01, -0.16, 0 );
      _this.tip.isVisible = false;

      _this.ring = BABYLON.MeshBuilder.CreateCylinder("ring1", {diameter: .7, height: .12}, _this.scene);
      _this.ring.position = new BABYLON.Vector3( 0, 0.2, 0 );
      _this.ring.isVisible = false;

      _this.top.addChild(topMesh);
      _this.top.addChild(_this.tip);
      _this.top.addChild(_this.ring);

      _this.tip.physicsImpostor = new BABYLON.PhysicsImpostor(_this.tip, BABYLON.PhysicsImpostor.SphereImpostor, { mass: 0, friction: 0.5, restitution: 0.5 } );
      _this.ring.physicsImpostor = new BABYLON.PhysicsImpostor(_this.ring, BABYLON.PhysicsImpostor.CylinderImpostor, { mass: 0, friction: 0.2, restitution: 0.8 } );
      _this.top.physicsImpostor = new BABYLON.PhysicsImpostor(_this.top, BABYLON.PhysicsImpostor.NoImpostor, { mass: 1, friction: 0.5, restitution: 0.5 });

      // All this does is move the entire assembly to the start position
      _this.top.position = new BABYLON.Vector3( 0, .21, 0 );
      _this.top.rotationQuaternion = new BABYLON.Quaternion(0, 0, Utilities.toRadians(0 / 2), 1);

      _this.allLoaded();
    });
  }

  allLoaded() {
    this.props.onAssetsLoaded(); // let root know to remove preloader and display game
    this.initAnimation();
    setTimeout( ()=> this.animateCamera("intro"), 1200); // day by 1 second to allow preloader to finish
  }

  unmounted() {
    this.engine.stopRenderLoop(this.renderFrame);
  }

  onResize(w, h) {
    this.canvas.width = w;
    this.canvas.height = h;
  }

  animateCamera(which) {
    let pos; // new position of camera;
    let targ; // new position of camTarget
    let dur; // duration of camTarget animation in frames (30/sec)
    let ease = new BABYLON.QuadraticEase(); // ease for camTarget animation
    ease.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEOUT);

    switch(which) {
      case "intro":
        this.camera.cameraAcceleration = 0.006;
        pos = new BABYLON.Vector3(30, 1.2, -6);
        dur = 180; // 6 secs
        targ = new BABYLON.Vector3(0, this.top.position.y + .3, 0);
        break;
      case "spin":
        this.camera.cameraAcceleration = 0.01;
        pos = new BABYLON.Vector3(20, 3.5, -9);
        dur = 180; // 6 secs
        targ = new BABYLON.Vector3(0, 1, 0);
        break;
      case "stop":
        this.camera.cameraAcceleration = 0.01;
        pos = new BABYLON.Vector3(-10, 1.5, -6);
        dur = 180; // 6 secs
        targ = new BABYLON.Vector3(this.top.position.x, this.top.position.y + .3, this.top.position.z)
        //targ = this.top.position //new BABYLON.Vector3(0, .5, 0);
        break;
      default:
        break;
    }
    this.camera.rotationOffset = pos.x;
    this.camera.heightOffset = pos.y;
    this.camera.radius = pos.z;
    //this.camTarget.position = targ;
    if( this.camTargetAnimation ) this.camTargetAnimation.stop();
    this.camTargetAnimation = BABYLON.Animation.CreateAndStartAnimation("camTargetAnimation_1", this.camTarget, "position", 30, dur, this.camTarget.position, targ, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT, ease);
  }

  spin(a = 0.1, s = 1) {
    let angle = a;
    let speed = s;
    this.top.position = new BABYLON.Vector3( 0, 0.3, 0 );
    this.top.rotationQuaternion = new BABYLON.Quaternion(0, 0, angle, 1); // drop angle here
    this.top.physicsImpostor.physicsBody.setDamping(0, 0.08);
    this.top.physicsImpostor.setAngularVelocity(new BABYLON.Quaternion(0, speed, 0, 0));
    this.animateCamera("spin");
    setTimeout(() => {
      this.spinning = true; // in a setTimeout to avoid conflict with the ring already touching the ground
    }, 500);
  }

  stopSpin() {
    this.spinning = false;
    this.animateCamera("stop");
    this.props.onStopSpin();
  }

  initAnimation() {
    this.engine.runRenderLoop(this.renderFrame);
  }

  renderFrame() {
    if(this.resizeRendererToDisplaySize()) { this.engine.resize() }
    if(this.ring.intersectsMesh(this.ground) && this.spinning === true) { this.stopSpin() }
    this.scene.render();
  }

  resizeRendererToDisplaySize() {
    const width = this.canvas.clientWidth;
    const height = this.canvas.clientHeight;
    const needResize = this.canvas.width !== width || this.canvas.height !== height;
    return needResize;
  }
}

export default World;
