import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from './assets/navlogo.svg';

class Nav extends Component {

  render() {
    //can execute functions etc here
    return ( // this is the "view"
      <nav className="Nav">
        <Link className="logo" to="/">
          <img src={logo} alt="Tip Top Studio"/>
        </Link>
        {/* <Link to="/shop" className="navLink" href="mailto:hello@tiptopstudio.com">Shop</Link> */}
        <a className="navLink" href="mailto:hello@tiptopstudio.com">Say Hello</a>
        <div className="clear"></div>
      </nav>
    );
  }
}


export default Nav;
