import React, { Component } from 'react';
import Tile from './Tile';
import top_square1 from './assets/top_square1.svg';
import top_square2 from './assets/top_square2.svg';
import top_square3 from './assets/top_square3.svg';
import ttgr_thumb_home from './assets/work/ttgr/ttgr_thumb_home.mp4';
import ttgr_thumb_home_2 from './assets/work/ttgr/ttgr_thumb_home_2.mp4';
import relay_brand_thumb_home from './assets/work/relay_brand/this_is_relay_thumb_home.mp4';
import relay_logo_thumb_home from './assets/work/relay_brand/relay_logo_thumb_home.mp4';
import buffalo_map_thumb_home from './assets/work/buffalo_map/buffalo_map_thumb_home.jpg';
import buffalo_map_thumb_home_2 from './assets/work/buffalo_map/buffalo_map_thumb_home_2.jpg';
import food_map_thumb_home from './assets/work/food_map/food_map_thumb_home.jpg';
import thumb_bark from './assets/work/bark/bark_square.svg';
import bandwidth_logo from './assets/work/bandwidth/bandwidth_thumb_home.jpg';
import bandwidth_logo_2 from './assets/work/bandwidth/bandwidth_thumb_home_2.mp4';
import bandwidth_logo_3 from './assets/work/bandwidth/bandwidth_thumb_home_3.jpg';
import sensual_sax from './assets/work/sensual_sax/sensual_sax_thumb_home.jpg';
import levitate_thumb from './assets/work/levitate/levitate-1.mp4';
import levitate_web_thumb from './assets/work/levitate/levitate-home.png';
import barrier_breaker_thumb from './assets/work/barrier_breaker/barrier_breaker_thumb_home.mp4';
import ramones_thumb from './assets/work/ramones/ramones_thumb_home.jpg';
import sim_thumb from './assets/work/sim/sim_thumb_home.jpg';
import datagrams_thumb_home from './assets/work/datagrams/datagrams_thumb_home.jpg';






class Work extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    // };

    // the model
    this.Tiles = [
      [
        {//1
          id: "levitate",
          size: "tier2",
          action: "link",
          thumbmedia: "video",
          thumburl: levitate_thumb,
          thumbcaption: "eyes",
          detailmedia: "",
          detailurl: "/levitate",
          detailcaption: ""
        },{//2
          id: "bark",
          size: "tier1",
          action: "link",
          thumbmedia: "image",
          thumburl: thumb_bark,
          thumbcaption: "eyes",
          detailmedia: "",
          detailurl: "/bark",
          detailcaption: ""
        },{//3
          id: "bandwidth_logo_2",
          size: "tier1",
          action: "link",
          thumbmedia: "video",
          thumburl: bandwidth_logo_2,
          thumbcaption: "eyes",
          detailmedia: "",
          detailurl: "/bandwidth",
          detailcaption: ""
        },{//4
          id: "buffalo_map",
          size: "tier1",
          action: "link",
          thumbmedia: "image",
          thumburl: buffalo_map_thumb_home,
          thumbcaption: "eyes",
          detailmedia: "",
          detailurl: "/buffalo_map",
          detailcaption: ""
        },{//5
          id: "relay_logo",
          size: "tier1",
          action: "link",
          thumbmedia: "video",
          thumburl: relay_logo_thumb_home,
          thumbcaption: "eyes",
          detailmedia: "",
          detailurl: "/relay_brand",
          detailcaption: ""
        }
      ],[
        {//1
          id: "food_map",
          size: "tier1",
          action: "link",
          thumbmedia: "image",
          thumburl: food_map_thumb_home,
          thumbcaption: "eyes",
          detailmedia: "",
          detailurl: "/food_map",
          detailcaption: ""
        },{//2
          id: "datagrams",
          size: "tier1",
          action: "link",
          thumbmedia: "image",
          thumburl: datagrams_thumb_home,
          thumbcaption: "eyes",
          detailmedia: "",
          detailurl: "datagrams",
          detailcaption: ""
        },{//3
          id: "relay_brand",
          size: "tier2",
          action: "link",
          thumbmedia: "video",
          thumburl: relay_brand_thumb_home,
          thumbcaption: "eyes",
          detailmedia: "",
          detailurl: "/relay_brand",
          detailcaption: ""
        },{//4
          id: "levitate_web",
          size: "tier1",
          action: "link",
          thumbmedia: "image",
          thumburl: levitate_web_thumb,
          thumbcaption: "eyes",
          detailmedia: "",
          detailurl: "/levitate",
          detailcaption: ""
        },{//5
          id: "sim",
          size: "tier1",
          action: "link",
          thumbmedia: "image",
          thumburl: sim_thumb,
          thumbcaption: "eyes",
          detailmedia: "",
          detailurl: "/sim",
          detailcaption: ""
        }
      ],[
        {//1
          id: "ttgr",
          size: "tier2",
          action: "link",
          thumbmedia: "video",
          thumburl: ttgr_thumb_home,
          thumbcaption: "eyes",
          detailmedia: "image",
          detailurl: "/time_to_get_a_relay",
          detailcaption: ""
        },{//2
          id: "bandwidth_logo_3",
          size: "tier1",
          action: "link",
          thumbmedia: "image",
          thumburl: bandwidth_logo_3,
          thumbcaption: "eyes",
          detailmedia: "",
          detailurl: "/bandwidth",
          detailcaption: ""
        },{//3
          id: "fpo",
          size: "tier1",
          action: "",
          thumbmedia: "image",
          thumburl: top_square2,
          thumbcaption: "",
          detailmedia: "",
          detailurl: "",
          detailcaption: ""
        },{//4
          id: "barrier_breaker",
          size: "tier1",
          action: "link",
          thumbmedia: "video",
          thumburl: barrier_breaker_thumb,
          thumbcaption: "eyes",
          detailmedia: "",
          detailurl: "/barrier_breaker",
          detailcaption: ""
        },{//5
          id: "ttgr_2",
          size: "tier1",
          action: "link",
          thumbmedia: "video",
          thumburl: ttgr_thumb_home_2,
          thumbcaption: "eyes",
          detailmedia: "",
          detailurl: "/time_to_get_a_relay",
          detailcaption: ""
        }
      ],[
          {//1
          id: "fpo",
          size: "tier1",
          action: "",
          thumbmedia: "image",
          thumburl: top_square3,
          thumbcaption: "",
          detailmedia: "",
          detailurl: "",
          detailcaption: ""
        },{//2
          id: "ramones",
          size: "tier1",
          action: "link",
          thumbmedia: "image",
          thumburl: ramones_thumb,
          thumbcaption: "eyes",
          detailmedia: "",
          detailurl: "/ramones",
          detailcaption: ""
        },{//3
          id: "bandwidth__logo",
          size: "tier2",
          action: "link",
          thumbmedia: "image",
          thumburl: bandwidth_logo,
          thumbcaption: "eyes",
          detailmedia: "",
          detailurl: "/bandwidth",
          detailcaption: ""
        },{//4
          id: "sensual_sax",
          size: "tier1",
          action: "link",
          thumbmedia: "image",
          thumburl: sensual_sax,
          thumbcaption: "eyes",
          detailmedia: "",
          detailurl: "/sensual_sax",
          detailcaption: ""
        },{//5
          id: "buffalo_map_2",
          size: "tier1",
          action: "link",
          thumbmedia: "image",
          thumburl: buffalo_map_thumb_home_2,
          thumbcaption: "eyes",
          detailmedia: "",
          detailurl: "/buffalo_map",
          detailcaption: ""
        }
      ]
    ];
  }

  renderTiles() {
    let tiles = [];
    let n = 0;
    for (let i = 0; i < this.Tiles.length; i++) {
      for (let j = 0; j < this.Tiles[i].length; j++) {
        tiles.push(
          <Tile key={n} {...this.Tiles[i][j]} />
        )
        n++;
      }
    }
    return tiles;
  }

  render() {
    //can execute functions etc here
    return (
      <section id="work" className="WorkGrid">
         {this.renderTiles()}
      </section>
    );
  }
}

export default Work;
