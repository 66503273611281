
class Clock {
  
  constructor(props) {
    this.props = props;
    this.time = 0;
    this.startTime = null;
    this.interval = null;
  }

  startTimer() {
    let _this = this;
    if(this.interval) this.resetTimer();
    this.interval = setInterval( () => _this.onInterval(), 10);
  }

  onInterval() {
    this.time++;
    //let ms = time;
    let s = Math.floor(this.time / 100)
    let ms = Math.floor(this.time % 100);
    if(ms<10){ ms = ":0" + ms.toString() }
    else ms = ":" + ms.toString();
    this.props.render(s, ms)
  }

  stopTimer() {
    this.clearTimer();
  }

  clearTimer() {
    clearInterval(this.interval);
  }

  resetTimer() {
    this.clearTimer();
    this.time = 0;
  }

}
export default Clock;
