class Utilities {

  //toRadians(angle in degrees)
  static toRadians(deg) {
    return deg * Math.PI/180;
  }
  // limit(initial value, what you want to limit it to)
  static limit(v, l) {
    let val
    if(v >= 0) val = v > l? l: v;
    else val = v < -l? -l: v;
    return val;
  }
  // dampen(current value, goal, acceleration)
  static dampen(v, g, a=4) {
    let dist = (v - g) / a;
    return v - dist;
  }

}
export default Utilities;
